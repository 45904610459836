import React from 'react';
import Link from 'next/link';
import styled, {css} from 'styled-components';

import Icon from 'components/icons/icon';

import theme, {bp, color} from 'settings/theme';


type Props = {
  href: string,
  as?: string,
  onClick?: () => void,
  children?: React.ReactNode
};

type StyledProps = {};


// Component
const Component: React.FC<Props> = (props) => {

  return (

    <StyledComponent className="footerMenuItem">
      
      <Link href={props.href} as={props.as} onClick={props.onClick}>
        <div className="footerMenuItem-icon">
          <Icon iconName="chevron" iconDirection="right" />
        </div>
        <div className="footerMenuItem-text">
          {props.children}
        </div>
      </Link>

    </StyledComponent> 
      
  );

}

export default Component


const StyledComponent = styled.div<StyledProps>`

  a{

    display: flex;
    align-items: center;
    height: 30px;
    color: ${color.grey(30)};
    font-family: ${theme.typography.fontFamilySecondary};

    &:hover, &:focus{
      color: ${color.blue};
    }

  }

  .footerMenuItem-icon{

    margin-right: 8px;

    .icon{
      width: 12px;
      height: 12px;
    }

    svg{
      fill: ${color.grey(30)}
    }

  }

  @media (min-width: ${bp(3)}) {

    a{
      height: 40px;
    }

    .footerMenuItem-icon{

      margin-right: 10px;

      .icon{
        width: 14px;
        height: 14px;
      }

    }

  }

`
